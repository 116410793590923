import { gql } from 'nuxt-graphql-request'

export const globalSetNewsDefaults = gql`
  query globalSetNewsDefaults($lang: String) {
    globalSet(handle: "news", site: $lang) {
      ... on GlobalSet_News {
        heading
        link_text
        link
      }
    }
  }
`

export const globalSetSwisstainableDefaults = gql`
  query globalSetSwisstainableDefaults($lang: String) {
    globalSet(handle: "swisstainable_hub", site: $lang) {
      ... on GlobalSet_SwisstainableHub {
        heading
        link_text
        link
      }
    }
  }
`

export const globalSetOffers = gql`
  query globalSetOffers($lang: String) {
    globalSet(handle: "offers", site: $lang) {
      ... on GlobalSet_Offers {
        heading
        link_text
        link
      }
    }
  }
`

export const globalSetMemberOffers = gql`
  query globalSetMemberOffers($lang: String) {
    globalSet(handle: "member_offers", site: $lang) {
      ... on GlobalSet_MemberOffers {
        heading
        link_text
        link
      }
    }
  }
`

export const globalSetNewsletter = gql`
  query globalSetNewsletter($lang: String) {
    globalSet(handle: "newsletter", site: $lang) {
      ... on GlobalSet_Newsletter {
        subtitle
        heading
        button_text
        success_title
        success_text
        groups {
          value
          label
        }
      }
    }
  }
`

export const globalSetContact = gql`
  query globalSetContact($lang: String) {
    globalSet(handle: "contact", site: $lang) {
      ... on GlobalSet_Contact {
        address
        phone
        address_url
        email
        coordinates {
          lat
          long
        }
        handle
        social_media_links: social_media_elements {
          link
          icon: channel {
            value
            label
          }
        }
        general_links {
          ... on Entry_Pages_Page {
            id
            title
            url
          }
        }
      }
    }
  }
`

export const globalSetSeo = gql`
  query globalSetSeo($lang: String) {
    globalSet(handle: "seo", site: $lang) {
      ... on GlobalSet_Seo {
        seo_title
      }
    }
  }
`

export const globalSetChatIntegration = gql`
  query globalSetChatIntegration($lang: String) {
    globalSet(handle: "chat_integration", site: $lang) {
      ... on GlobalSet_ChatIntegration {
        embed_code
      }
    }
  }
`

// GET Navigations
export const getNavigations = gql`
  query getNavigations($lang: String) {
    navs {
      title
      handle
      tree(site: $lang) {
        page {
          id
          title
          url
          ... on NavEntryPage_Main_Pages_Page {
            is_right
            is_cta_button
            is_hidden
            status
          }
          ... on NavBasicPage_Main {
            is_cta_button
            is_right
            is_hidden
          }
        }
        children {
          page {
            id
            title
            url
            ... on NavEntryPage_Main_Pages_Page {
              is_right
              is_cta_button
              is_hidden
              status
            }
            ... on NavBasicPage_Main {
              is_cta_button
              is_hidden
              is_right
            }
          }
          children {
            page {
              id
              title
              url
              ... on NavEntryPage_Main_Pages_Page {
                is_right
                is_cta_button
                is_hidden
                status
              }
              ... on NavBasicPage_Main {
                is_cta_button
                is_hidden
                is_right
              }
            }
            children {
              page {
                id
                title
                url
                ... on NavEntryPage_Main_Pages_Page {
                  is_right
                  is_cta_button
                  is_hidden
                  status
                }
                ... on NavBasicPage_Main {
                  is_cta_button
                  is_hidden
                  is_right
                }
              }
              children {
                page {
                  id
                  title
                  url
                  ... on NavEntryPage_Main_Pages_Page {
                    is_right
                    is_cta_button
                    is_hidden
                    status
                  }
                  ... on NavBasicPage_Main {
                    is_cta_button
                    is_hidden
                    is_right
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
