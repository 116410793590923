import {
  globalSetNewsDefaults,
  globalSetSwisstainableDefaults,
  globalSetNewsletter,
  globalSetContact,
  globalSetOffers,
  globalSetMemberOffers,
  globalSetSeo,
  globalSetChatIntegration,
} from '~/graphql/globals'

export const state = () => ({
  newsDefaults: null,
  swisstainableDefaults: null,
  contact: null,
  newsletterTexts: null,
  offerData: null,
  memberOfferData: null,
  seo: null,
  chatIntegration: null,
})

export const mutations = {
  setNewsDefaults(state, newsDefaults) {
    state.newsDefaults = newsDefaults
  },

  setSwisstainableDefaults(state, swisstainableDefaults) {
    state.swisstainableDefaults = swisstainableDefaults
  },

  setNewsDefaultsletterTexts(state, newsletterTexts) {
    state.newsletterTexts = newsletterTexts
  },

  setContact(state, contact) {
    state.contact = contact
  },
  setOffers(state, offers) {
    state.offerData = offers
  },
  setMemberOffers(state, memberOffers) {
    state.memberOfferData = memberOffers
  },
  setSeo(state, seo) {
    state.seo = seo
  },
  setChatIntegration(state, chatIntegration) {
    state.chatIntegration = chatIntegration
  },
}

export const getters = {
  contact(state) {
    return state.contact
  },
  seo(state) {
    return state.seo
  },
  chatIntegration(state) {
    return state.chatIntegration
  },
}

export const actions = {
  async getNewsDefaults({ commit }) {
    try {
      const { globalSet } = await this.$graphql.cms.request(
        globalSetNewsDefaults,
        {
          lang: this.$i18n.locale,
        }
      )

      commit('setNewsDefaults', globalSet)
    } catch (e) {
      console.error('globals/getNewsDefaults', e)
    }
  },

  async getSwisstainableDefaults({ commit }) {
    try {
      const { globalSet } = await this.$graphql.cms.request(
        globalSetSwisstainableDefaults,
        {
          lang: this.$i18n.locale,
        }
      )

      commit('setSwisstainableDefaults', globalSet)
    } catch (e) {
      console.error('globals/getSwisstainableDefaults', e)
    }
  },

  async getNewsletterTexts({ commit }) {
    try {
      const { globalSet } = await this.$graphql.cms.request(
        globalSetNewsletter,
        {
          lang: this.$i18n.locale,
        }
      )

      commit('setNewsDefaultsletterTexts', globalSet)
    } catch (e) {
      console.error('globals/getNewsletterTexts', e)
    }
  },

  async getContact({ commit }) {
    try {
      const { globalSet } = await this.$graphql.cms.request(globalSetContact, {
        lang: this.$i18n.locale,
      })

      commit('setContact', globalSet)
    } catch (e) {
      console.error('globals/getContact', e)
    }
  },

  async getOffers({ commit }) {
    try {
      const { globalSet } = await this.$graphql.cms.request(globalSetOffers, {
        lang: this.$i18n.locale,
      })

      commit('setOffers', globalSet)
    } catch (e) {
      console.error('globals/getOffers', e)
    }
  },

  async getMemberOffers({ commit }) {
    try {
      const { globalSet } = await this.$graphql.cms.request(
        globalSetMemberOffers,
        {
          lang: this.$i18n.locale,
        }
      )

      commit('setMemberOffers', globalSet)
    } catch (e) {
      console.error('globals/getMemberOffers', e)
    }
  },

  async getSeo({ commit }) {
    try {
      const { globalSet } = await this.$graphql.cms.request(globalSetSeo, {
        lang: this.$i18n.locale,
      })

      commit('setSeo', globalSet)
    } catch (e) {
      console.error('globals/getSeo', e)
    }
  },

  async getChatIntegration({ commit }) {
    try {
      const { globalSet } = await this.$graphql.cms.request(
        globalSetChatIntegration,
        {
          lang: this.$i18n.locale,
        }
      )

      commit('setChatIntegration', globalSet)
    } catch (e) {
      console.error('globals/getChatIntegration', e)
    }
  },
}
