export const actions = {
  async nuxtServerInit({ dispatch }) {
    try {
      await Promise.all([
        dispatch('navigations/getAllNavs'),
        dispatch('globals/getContact'),
        dispatch('globals/getNewsletterTexts'),
        dispatch('globals/getNewsDefaults'),
        dispatch('globals/getSwisstainableDefaults'),
        dispatch('globals/getOffers'),
        dispatch('globals/getMemberOffers'),
        dispatch('globals/getSeo'),
        dispatch('globals/getChatIntegration'),
      ])
    } catch (e) {
      console.log('nuxtServerInit:', e)
    }
  },
}
