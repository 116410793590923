
import { mapState } from 'vuex'

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: false,
    }
  },

  computed: {
    ...mapState('seo', ['pageHasAlternates']),
    ...mapState('i18n', ['routeParams']),

    locales() {
      return Object.keys(this.routeParams).filter(
        (code) => code !== this.$i18n.locale
      )
    },
  },

  watch: {
    async '$i18n.locale'(newVal, oldVal) {
      try {
        await Promise.all([
          this.$store.dispatch('navigations/getAllNavs'),
          this.$store.dispatch('globals/getContact'),
          this.$store.dispatch('globals/getNewsletterTexts'),
          this.$store.dispatch('globals/getNewsDefaults'),
          this.$store.dispatch('globals/getSwisstainableDefaults'),
          this.$store.dispatch('globals/getOffers'),
          this.$store.dispatch('globals/getMemberOffers'),
          this.$store.dispatch('globals/getSeo'),
          this.$store.dispatch('globals/getChatIntegration'),
        ])
      } catch (e) {
        console.log('Error on language switch:', e)
      }
    },
  },

  methods: {
    toggle() {
      this.open = !this.open
    },

    close() {
      this.open = false
    },
  },
}
